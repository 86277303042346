<template>
  <nav
    v-if="'index' !== $route.name && 'reach-campaigns-bms-packages-create' !== $route.name"
    class="flex pb-4"
    aria-label="Breadcrumb"
  >
    <ol class="flex items-center space-x-4">
      <li>
        <div>
          <NuxtLink
            :to="{name: 'index'}"
            class="text-gray-dark hover:text-gray-darker"
          >
            <!-- Heroicon name: solid/home -->
            <svg
              class="size-5 flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
            <span class="sr-only">Dashboard</span>
          </NuxtLink>
        </div>
      </li>
      <li
        v-for="(crumb, index) in crumbs"
        :key="index"
      >
        <div class="flex items-center">
          <!-- Heroicon name: solid/chevron-right -->
          <svg
            class="size-5 flex-shrink-0 text-gray"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          <NuxtLink
            :to="crumb.path"
            class="ml-4 text-sm font-medium text-gray-dark hover:text-gray-darker"
          >
            {{ $route.fullPath === crumb.path && title !== null ? title : crumb.title }}
          </NuxtLink>
        </div>
      </li>
      <template v-if="hasUuid">
        <li
          v-for="(crumb, index) in customCrumbs"
          :key="`custom-${index}`"
        >
          <div class="flex items-center">
            <!-- Heroicon name: solid/chevron-right -->
            <svg
              class="size-5 flex-shrink-0 text-gray"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <NuxtLink
              :to="crumb.path"
              class="ml-4 text-sm font-medium text-gray-dark hover:text-gray-darker"
            >
              {{ crumb.title }}
            </NuxtLink>
          </div>
        </li>
      </template>
    </ol>
  </nav>
</template>

<script>
import {apStyleTitleCase as titleCase} from 'ap-style-title-case';
import {useBreadcrumbsStore} from '~/stores/breadcrumbs';

export default {
  setup() {
    const {result} = useBreadcrumbsQuery();
    const route = useRoute();
    const router = useRouter();

    const breadcrumbs = useBreadcrumbsStore();

    return {
      breadcrumbs: computed(() => result.value?.breadcrumbs ?? []),
      customCrumbs: computed(() => breadcrumbs.customCrumbs),
      route,
      router,
      title: computed(() => breadcrumbs.title),
    };
  },

  data: () => ({
    manualMapping: {
      create: 'Toevoegen',
      edit: 'Bewerken',
      news: 'Nieuws',
      plans: 'Jaarplannen',
      campaigns: 'Mediapakketten',
      'promotional-positions': 'Mediaposities',
      'bms-positions': 'Mediaposities',
      'bms-packages': 'Mediapakketten',
      success: 'Bedankt',
      buying: 'Jouw relaties',
      suppliers: 'Leveranciers',
      contact: 'Contact',
      shortcoming: 'Manco melden',
      insights: 'Inzichten',
    },
  }),
  computed: {
    params() {
      const fullPath = this.$route.path;
      return fullPath.startsWith('/')
        ? fullPath.substring(1).split('/')
        : fullPath.split('/');
    },
    crumbs() {
      const params = this.params;
      const crumbs = [];
      let path = '';

      params.forEach((param, index) => {
        if (/[\w]{8}(-[\w]{4}){3}-[\w]{12}/.test(param)) {
          // Skip uuids
          return;
        }

        path = `${path}/${param}`;

        const match = this.router.resolve(path);

        if (match.name !== null) {
          crumbs.push({
            title: this.prepareCrumb(path, param),
            ...match,
          });
        }
      });

      return crumbs;
    },
    hasUuid() {
      let hasUuid = false;

      this.params.forEach((param) => {
        if (/[\w]{8}(-[\w]{4}){3}-[\w]{12}/.test(param)) {
          // Skip uuids
          hasUuid = true;
        }
      });

      return hasUuid;
    },
    mapping() {
      const mapping = [];

      this.breadcrumbs.forEach(item => mapping[item.slug] = item.title);

      return mapping;
    },
  },
  methods: {
    prepareCrumb(path, param) {
      return this.manualMapping[param] || this.mapping[this.trim(path, '/')] || this.mapping[param] || titleCase(param.replace(/-/g, ' '));
    },
    trim(str, ch) {
      let start = 0;
      let end = str.length;

      while (start < end && str[start] === ch) { ++start; }

      while (end > start && str[end - 1] === ch) { --end; }

      return (start > 0 || end < str.length) ? str.substring(start, end) : str;
    },
  },
};
</script>
